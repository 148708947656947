@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  font-family: "Poppins", sans-serif !important;
/*  color: #4d5875;*/
}

.mainWrapper{
  padding: 10px;
  font-size: 14px;
  flex: 1;
}

.footerToolbar{
  padding: 0 10px;
}

.title{
  font-size: 16px;
  font-weight: 500;
}

.containerCenter {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 10px;
}

.menuButton {
  width: 80px;
  height: 60px;
  border-radius: 8px;
  border: 1px solid #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 12px;
  color: var(--ion-text-color);
  box-shadow: 2px 2px 2px #ccc;
}

.menuButton.active{
  color: green !important;  
  font-weight: 500;
}

.menuButton ion-icon {
  font-size: 16px !important;
}

/* Form */
ion-item.input {
  --min-height: 20px !important;
  --padding-start: 8px !important;
  --inner-padding-end: 8px !important;
  font-size: 14px !important;
}
.inputWrapper{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}
.inputContainer {
  margin-bottom: 10px;
  width: 100%;
/*  border-radius: 8px;*/
}

.input {
  border: 1px solid #ccc;
  border-radius: 8px;  
}

ion-button.button {
  color: #ccc !important;
}

.packages, .items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.itemTitle {
  font-size: 13px;
}

.itemDesc {
  font-size: 12px;
}

.packagePrice {
  font-size: 12px;
}

.packageItems {
  font-size: 12px;
}

.menu{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.mb20{
  margin-bottom: 20px;
}

.textSmall {
  font-size: 12px;
}

ion-item .item-native .item-inner {
  border-width: 0 !important;
}

.sectionContainer, .orderContainer{
  background: #f2f2f2;
  margin-bottom: 10px;
  border-radius: 8px;
}

.transactionContainer {
  background: #f2f2f2;
  margin-bottom: 6px;
  border-radius: 8px;
}


.orderHeader{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.splashContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.splashSpinner{
  position: absolute;
}

.splashSpinner ion-spinner{
  color: gray;
}

.buttonQtyContainer {
  display: flex;
  width: 130px;
  border: 1px solid #f2f2f2 !important;
  --border-radius: 4px;
  z-index: 99999;
}

.buttonQtyContainer ion-input{
  padding: 0px !important;
}

.buttonQtyContainer input {
  --padding-start: 0;
  padding: 0px !important;
}

.buttonQtyContainer ion-button{
  margin-inline: 0;
  margin: 0; 
}

.buttonQtyContainer ion-button.buttonQtyLeft{
  --border-radius: 0px;
/*  --border-top-left-radius: 4px !important;*/
/*  --border-bottom-right-radius: 4px !important;*/
}

.buttonQtyContainer ion-button.buttonQtyRight{
  --border-radius: 0px;
}

.memberContainer {
  background: #f2f2f2;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 8px;
}

ion-title {
  padding-inline: 0;  
}


/*ion-footer.cardedFooter>*/
.footer-md::before{
  background: transparent !important;
  height: 0px;
}

ion-toolbar.header-stockiest {
  --background: gold !important;
}

ion-toolbar.header-depot {
  --background: silver;
}

ion-toolbar.header-distributor {
  --background: brown;  
}

.formLabel {
  margin-bottom: .375rem;
  font-weight: 500;
  color: #9393b5;
}

ul.pagination{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

ul.pagination li{
  list-style: none;
  border: 1px solid;
  padding: 4px 8px;
  margin: 0 4px;
  border-radius: 8px;
  min-width: 40px;
  text-align: center;
  cursor: pointer;
}

ul.pagination li.disabled{
  background: #999;
}

ul.pagination li.selected{
  background: #CCC;
}

.alignRight{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

h1, h2, h3, h4, h5, h6{
  margin-bottom: 0px;
}

.itemsCenter{
  display: flex;
  justify-content: center;
  align-items: center;
}